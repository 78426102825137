@import url("./styles/variables.css");

/* ReactModal Overlay */
.ReactModal__Overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.75);
  display: flex;
  justify-content: center;
  align-items: center;
}

/* ReactModal Content */
.ReactModal__Content {
  position: relative;
  background: var(--background-color);
  padding: 20px;
  border-radius: var(--border-radius);
  width: 80%;
  height: 80%;
  max-width: 650px;
  max-height: 620px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  overflow-y: auto;
}

/* Form Styles */
form#contactForm {
  display: flex;
  flex-direction: column;
  margin-bottom: 0;
}

form#contactForm label {
  font: 12px / 22px "opensans-bold", sans-serif;
  margin: 12px 0;
  color: #3d4145;
  display: block;
}

form input[type="submit"] {
  background-color: var(--primary-color);
  color: #fff;
  border: none;
  cursor: pointer;
  transition: background-color var(--transition-duration) ease;
}

form input[type="submit"]:hover {
  background-color: var(--secondary-color);
}

.close-button {
  background: none;
  border: none;
  font-size: 20px;
  position: absolute;
  color: #333;
  top: 10px;
  right: 10px;
  cursor: pointer;
}

.success-message {
  text-align: center;
}

.success-message p {
  font-size: var(--font-size-large);
  color: var(--success-color);
}

.error {
  color: var(--error-color);
  font-size: var(--font-size-small);
}

.success {
  color: var(--success-color);
  font-size: var(--font-size-small);
}

.contact-button {
  background: var(--primary-color);
  border: none;
  color: white;
  cursor: pointer;
  font-size: inherit;
  padding: 0;
}

.contact-button:hover {
  color: var(--secondary-color);
}

.form-group {
  margin: auto;
}

.form-group input,
.form-group textarea {
  width: 70vw;
  max-width: 550px;
  margin: 0 0 12px 0;
  padding: 12px;
  border-radius: var(--border-radius);
}

.form-group textarea {
  min-height: 150px;
}

.form-button {
  margin: 5px auto;
}

.form-button button.submit {
  background-color: var(--primary-color);
  margin-bottom: 0;
  padding: 3px 20px;
}

/* Testimonials Section */
#testimonials {
  padding: 50px 0;
}

#testimonials .text-container {
  text-align: center;
}

.testimonial-item {
  padding: 30px;
  background: #fff;
  max-width: 600px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin: 0 20px;
}

.testimonial-item blockquote {
  font-size: 18px;
  padding: 0;
  color: #333;
  margin: auto;
  position: relative;
  padding: 20px;
}

#testimonials blockquote p {
  font-family: "opensans-regular";
  padding: 0;
  font-size: 14px;
  line-height: 28px;
  color: #333;
}

.testimonial-item blockquote:before {
  content: "\201C";
  font-size: 50px;
  position: absolute;
  left: 10px;
  top: 10px;
  color: var(--primary-color);
}

.testimonial-item cite {
  display: block;
  font-family: "opensans-regular";
  font-size: 18px;
  line-height: 18px;
  color: #333;
  margin-top: 10px;
}
